import {
  GuidedCareTaskUpdateFormSchema,
  IGuidedCareTaskUpdateFormValues,
  guidedCareTaskUpdateFormDefaultValues,
} from "./GuidedCareTaskUpdateFormSchema";
import { GuidedCareTeamUsersAutocomplete } from "@health/autocompletes";
import { taskDefinitionStatusOptions } from "@health/enum-options";
import { AssigneeType, GuidedCareTask } from "@health/queries/types";
import { useCustomForm } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { FormAutocomplete, Grid } from "@toolkit/ui";
import { convertGuidedCareTaskToFormValues } from "../../others";
import { IGuidedCareTaskUpdateFormEvent } from "../../types";
import { ForwardRefRenderFunction, forwardRef, useEffect, useImperativeHandle } from "react";
import { FormProvider, UseFormReturn } from "react-hook-form";

type GuidedCareTaskUpdateFormProps = {
  guidedCareTask: GuidedCareTask;
  onChange: (event: IGuidedCareTaskUpdateFormEvent) => void;
};

export type GuidedCareTaskUpdateFormRef = {
  getForm: () => UseFormReturn<IGuidedCareTaskUpdateFormValues>;
  submit: () => void;
};

export const GuidedCareTaskUpdateFormForwardRef: ForwardRefRenderFunction<GuidedCareTaskUpdateFormRef, GuidedCareTaskUpdateFormProps> = (
  props,
  ref
) => {
  const { guidedCareTask, onChange } = props;

  const { t } = useTranslation("provider");

  const form = useCustomForm<IGuidedCareTaskUpdateFormValues>({
    defaultValues: guidedCareTaskUpdateFormDefaultValues,
    schema: GuidedCareTaskUpdateFormSchema,
  });

  const { setValues, handleSubmit } = form;

  const isAssigneeTeamMember = guidedCareTask?.assignee?.find(item => item?.assigneeType === AssigneeType.TeamMember);

  // todo: fix in https://app.clickup.com/t/7542143/ISSUE-11274
  const teamId = guidedCareTask?.guidedCareJourneyItem?.guidedCareJourney?.providerGuidedCareHealthProgramTeam?.id!;

  const onSubmit = (values: IGuidedCareTaskUpdateFormValues) => {
    if (guidedCareTask) {
      onChange({
        type: "UPDATE",
        payload: { values },
      });
    }
  };

  useImperativeHandle(ref, () => ({
    getForm: () => form,
    submit: handleSubmit(onSubmit),
  }));

  useEffect(() => {
    if (guidedCareTask) {
      const _guidedCareTask = convertGuidedCareTaskToFormValues(guidedCareTask);
      setValues(_guidedCareTask);
    }
  }, [guidedCareTask, setValues]);

  return (
    <FormProvider {...form}>
      <form>
        <Grid container spacing={2} mb={2}>
          {isAssigneeTeamMember && (
            <Grid item xs={12}>
              <GuidedCareTeamUsersAutocomplete name={"assignees"} label={t("Assignees")} multiple filter={{ teamId }} />
            </Grid>
          )}

          <Grid item xs={12}>
            <FormAutocomplete name={"status"} label={t("Status")} placeholder={t("Status")} options={taskDefinitionStatusOptions} />
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};

export const GuidedCareTaskUpdateForm = forwardRef(GuidedCareTaskUpdateFormForwardRef);
