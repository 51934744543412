import { useCallback, useState } from "react";
import { TPeer } from "@meetora/schema/Peer";

export type ChatNavItemId = "chats" | "channels" | "visits";

export const useChatHooks = () => {
  const [peer, setPeer] = useState<TPeer | undefined>();
  const [nav, setNav] = useState<ChatNavItemId | undefined>("chats");

  const handleNav = useCallback((v: ChatNavItemId | undefined) => setNav(v), [setNav]);
  const handleSelect = useCallback(setPeer, [setPeer]);
  const handleClear = useCallback(() => setPeer(undefined), []);

  return { peer, nav, handleNav, handleSelect, handleClear };
};
