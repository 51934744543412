import { Box, FormAutocomplete, Grid, Typography } from "@toolkit/ui";
import React from "react";
import { useTranslation } from "@toolkit/i18n";
import { useGuidedCareProgramMemberRiskFactorParametersEvaluationFormStyle } from "./useGuidedCareProgramMemberRiskFactorParametersEvaluationFormStyle";
import { useCustomFormContext } from "@toolkit/core";
import { IGuidedCareProgramMemberAddFormValues } from "../GuidedCareProgramMemberAdd/GuidedCareProgramMemberAddFormSchema";

export const GuidedCareProgramMemberRiskFactorParametersEvaluationForm = () => {
  const form = useCustomFormContext<IGuidedCareProgramMemberAddFormValues>();

  const { watch } = form;

  const evaluationResult = watch("evaluationResult");

  const { t } = useTranslation("provider");

  const { classes } = useGuidedCareProgramMemberRiskFactorParametersEvaluationFormStyle();

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} className={classes.gridItem}>
        <Typography className={classes.title}>{t("Evaluation Result")}</Typography>
      </Grid>

      <Grid item xs={12} className={classes.gridItem}>
        <Typography className={classes.label}>
          {t("Risk Level")}: <Typography className={classes.value}>{t(evaluationResult?.level!) || "-"}</Typography>
        </Typography>
      </Grid>

      <Grid item xs={12} className={classes.gridItem}>
        <Typography className={classes.label}>{t("Suggested Programs")}</Typography>

        {evaluationResult?.suggestedPrograms?.length ? (
          <Box className={classes.suggestedProgramWrapper}>
            <FormAutocomplete name={"suggestedProgram"} label={t("Suggested Program")} options={evaluationResult?.suggestedPrograms} />
          </Box>
        ) : (
          <Typography className={classes.value}> {t("There are no suggested programs")}. </Typography>
        )}
      </Grid>
    </Grid>
  );
};
