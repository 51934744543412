import { MarketplaceHealthPackagesAutocomplete, MarketplaceProductsAutocomplete } from "@health/autocompletes";
import { marketplaceDiscountTypeOptions } from "@health/enum-options";
import { MarketplaceDiscountType } from "@health/queries/types";
import { formGirdBreakPoints, formGirdSpacing } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { FormAutocomplete, FormDatePickerField, FormNumberField, FormTextField, Grid } from "@toolkit/ui";
import React, { FC } from "react";
import { useFormContext } from "react-hook-form";
import { IDiscountUpsertFormValues } from "../DiscountFormUpsert";
import { DiscountData } from "../../types/types";

type DiscountInformationFormProps = {
  discount?: DiscountData;
  isDiscountItemsDisplayed?: boolean;
};
export const DiscountInformationForm: FC<DiscountInformationFormProps> = ({ discount, isDiscountItemsDisplayed }) => {
  const { t } = useTranslation("provider");
  const { watch } = useFormContext<IDiscountUpsertFormValues>();
  const type = watch("type");

  return (
    <Grid container spacing={formGirdSpacing}>
      <Grid item {...formGirdBreakPoints}>
        <FormTextField name={"name"} label={t("Name")} />
      </Grid>
      {isDiscountItemsDisplayed && (
        <Grid item {...formGirdBreakPoints}>
          <MarketplaceProductsAutocomplete disabled={discount?.id} name={"product"} multiple />
        </Grid>
      )}
      {isDiscountItemsDisplayed && (
        <Grid item {...formGirdBreakPoints}>
          <MarketplaceHealthPackagesAutocomplete disabled={discount?.id} name={"healthPackage"} multiple />
        </Grid>
      )}
      <Grid item {...formGirdBreakPoints}>
        <FormAutocomplete name='type' label={t("Discount Type")} options={marketplaceDiscountTypeOptions} />
      </Grid>
      {type?.value === MarketplaceDiscountType.Fixed && (
        <Grid item {...formGirdBreakPoints}>
          <FormNumberField name='amount' label={t("Amount")} />
        </Grid>
      )}
      {type?.value === MarketplaceDiscountType.Percentage && (
        <Grid item {...formGirdBreakPoints}>
          <FormNumberField name='percentage' label={t("Percentage")} />
        </Grid>
      )}
      <Grid item {...formGirdBreakPoints}>
        <FormDatePickerField name='startDate' label={t("Start Date")} placeholder={t("Start Date")} />
      </Grid>
      <Grid item {...formGirdBreakPoints}>
        <FormDatePickerField name='endDate' label={t("End Date")} placeholder={t("End Date")} />
      </Grid>
    </Grid>
  );
};
