import { FC, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useVisitCallHook } from "./useVisitCallHook";
import { MeetoraCall } from "@health/meetora";

type VisitCallProps = {
  meetingLink: string;
  visitId: string;
};

export const VisitCall: FC<VisitCallProps> = ({ meetingLink: invitationLink, visitId }) => {
  const { renderDoctorConsole } = useVisitCallHook({ visitId });
  const navigate = useNavigate();

  const onRequestClose = useCallback(
    (closeWindow = false) => {
      if (closeWindow) {
        window.close();
      }
      navigate("/");
    },
    [navigate]
  );

  return <MeetoraCall invitationCode={invitationLink} onClose={onRequestClose} renderDoctorConsole={renderDoctorConsole} />;
};
