import { useCallback } from "react";
import { DoctorConsole } from "../DoctorConsole";
import { useOidcUserProfile } from "shared/hooks/useOidcUserProfile";
import { isDoctorUser } from "utils";

export const useVisitCallHook = ({ visitId }: { visitId: string }) => {
  const { userInfo } = useOidcUserProfile();
  const isDoctor = isDoctorUser(userInfo);
  const isDoctorConsoleEnabled = isDoctor && !!visitId;

  const renderDoctorConsole = useCallback(() => <DoctorConsole visitId={visitId} />, [visitId]);

  return { renderDoctorConsole: isDoctorConsoleEnabled ? renderDoctorConsole : undefined };
};
