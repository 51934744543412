import { getAutocompleteFilter, createAutocompleteOption } from "@toolkit/ui";
import { pickLocalizedValue } from "@toolkit/i18n";
import { useMarketplaceProductsAutocompleteQuery } from "./gql";
export const createMarketplaceProductAutocompleteOption = (product) => {
    return createAutocompleteOption({
        id: product === null || product === void 0 ? void 0 : product.id,
        name: product === null || product === void 0 ? void 0 : product.name,
        nameAr: product === null || product === void 0 ? void 0 : product.nameAr,
    }, "id", item => pickLocalizedValue(item === null || item === void 0 ? void 0 : item.name, item === null || item === void 0 ? void 0 : item.nameAr));
};
export const getMarketplaceProductsAutocompleteFilter = ({ name, multiple }) => {
    return getAutocompleteFilter({
        name,
        multiple,
        accessor: "marketplaceProducts",
        query: useMarketplaceProductsAutocompleteQuery,
        labelBy: item => pickLocalizedValue(item === null || item === void 0 ? void 0 : item.name, item === null || item === void 0 ? void 0 : item.nameAr),
        backendAccessor: "id",
        filterSearchKey: "nameContains",
    });
};
