import { getAutocompleteFilter, createAutocompleteOption } from "@toolkit/ui";
import { useDoctorPatientsAutocompleteQuery } from "./gql";
export const createDoctorPatientAutocompleteOption = (patient) => {
    return createAutocompleteOption({ id: patient === null || patient === void 0 ? void 0 : patient.id, fullName: patient === null || patient === void 0 ? void 0 : patient.fullName }, "id", "fullName");
};
export const getDoctorPatientsAutocompleteFilter = ({ name, multiple }) => {
    return getAutocompleteFilter({
        name,
        multiple,
        accessor: "doctorTeamEnrolledMembers",
        query: useDoctorPatientsAutocompleteQuery,
        labelBy: item => { var _a; return (_a = item === null || item === void 0 ? void 0 : item.user) === null || _a === void 0 ? void 0 : _a.fullName; },
        backendAccessor: item => { var _a; return (_a = item === null || item === void 0 ? void 0 : item.user) === null || _a === void 0 ? void 0 : _a.id; },
    });
};
