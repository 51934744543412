import { FC } from "react";
import { useSearchParams } from "react-router-dom";
import { VisitCall } from "./components";

export const VisitCallPage: FC = () => {
  const [params] = useSearchParams();

  return <VisitCall visitId={params.get("visitId")!} meetingLink={params.get("meetingLink")!} />;
};

export default VisitCallPage;
