/* eslint-disable max-lines */
import { getBranchesAutocompleteFilter, getDepartmentsAutocompleteFilter, getSystemCodeAutocompleteFilter } from "@health/autocompletes";
import { getAutocompleteEnumFilter } from "@health/enum-options";
import { CodeSystemCode, Doctor } from "@health/queries/types";
import { convertToTitleCase, formatUserNameFull } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { Avatar, CustomDialog, CustomTableColumnProps, Typography } from "@toolkit/ui";
import { useMemo } from "react";
import { doctorAvailabilityOptions } from "../utils";

export type ProfessionalsColumnsTypes = CustomTableColumnProps<Doctor>[];

export const useProfessionalsColumns = (): ProfessionalsColumnsTypes => {
  const { t } = useTranslation("provider");
  return useMemo(() => {
    return [
      {
        key: "photo",
        header: t("Photo"),
        accessor: ({ user }) => <Avatar src={user?.photo || ""} />,
        type: "string",
      },
      {
        key: "name",
        header: t("Name"),
        accessor: ({ user }) => formatUserNameFull(user!),
        isHidden: false,
        filter: {
          type: "string",
          name: "name",
        },
      },
      {
        key: "search",
        header: t("Search"),
        showOnlyForFilterField: true,
        filter: {
          type: "string",
          name: "search",
        },
      },
      {
        key: "availabilityStatus",
        header: t("Availability Status"),
        showOnlyForFilterField: true,
        filter: {
          type: "autocomplete",
          name: "availabilityStatus",
          options: doctorAvailabilityOptions,
          getOptionLabel: option => option?.label,
          getValueForBackend: option => option?.value,
        },
      },
      {
        key: "gender",
        header: t("Gender"),
        showOnlyForFilterField: true,
        filter: getAutocompleteEnumFilter("UserGender", "gender"),
      },
      {
        key: "gender",
        header: t("Gender"),
        accessor: ({ user }) => t(convertToTitleCase(user?.gender || "") || "-"),
        isHidden: false,
        type: "string",
      },
      {
        key: "yearsOfExperience",
        header: t("Years Of Experience"),
        accessor: ({ yearsOfExperience }) => yearsOfExperience || "-",
        type: "string",
      },
      {
        key: "nationalId",
        header: t("National Id"),
        accessor: ({ user }) => user?.nationalId || "-",
        type: "string",
      },
      {
        key: "seniority",
        header: t("Seniority"),
        accessor: "seniority",
        type: "string",
      },
      {
        key: "qualifications",
        header: t("Qualifications"),
        accessor: ({ qualifications }) => (
          <CustomDialog type='info' title={t("Doctor's Qualifications")}>
            <Typography sx={{ whiteSpace: "pre-line", lineHeight: 2 }}>
              {qualifications?.length
                ? qualifications?.map(qualification => qualification?.issuer).join("\n")
                : t("No qualifications provided")}
            </Typography>
          </CustomDialog>
        ),
        type: "string",
      },
      {
        key: "specializations",
        header: t("Specializations"),
        accessor: ({ specializations }) => (
          <CustomDialog type='info' title={t("Doctor's Specializations")}>
            <Typography sx={{ whiteSpace: "pre-line", lineHeight: 2 }}>
              {specializations?.length
                ? specializations?.map(specialization => specialization?.display).join("\n")
                : t("No specializations provided")}
            </Typography>
          </CustomDialog>
        ),
        filter: getSystemCodeAutocompleteFilter({
          name: "specializations",
          multiple: true,
          isCodeHidden: true,
          queryVariables: { codeSystemCode: CodeSystemCode.Speciality },
        }),
      },
      {
        key: "bio",
        header: t("Bio"),
        accessor: ({ bio }) => (
          <CustomDialog type='info' title={t("Doctor's Bio")}>
            <Typography sx={{ whiteSpace: "pre-line" }}>{bio ? bio : t("No biography provided")}</Typography>
          </CustomDialog>
        ),
        type: "string",
      },
      {
        key: "isActive",
        header: t("Is Active"),
        showOnlyForFilterField: true,
        filter: getAutocompleteEnumFilter("YesNo", "isActive"),
      },
      {
        key: "licenseNumber_Icontains",
        header: t("License Number"),
        showOnlyForFilterField: true,
        filter: { type: "string", name: "licenseNumber_Icontains" },
      },
      {
        key: "appointmentType",
        header: t("Appointment Types"),
        showOnlyForFilterField: true,
        filter: getAutocompleteEnumFilter("AppointmentType", "appointmentTypes", { multiple: true }),
      },
      {
        key: "branchId",
        header: t("Branch"),
        showOnlyForFilterField: true,
        filter: getBranchesAutocompleteFilter({ name: "branch" }),
      },
      {
        key: "departmentIds",
        header: t("Departments"),
        showOnlyForFilterField: true,
        filter: getDepartmentsAutocompleteFilter({ name: "departmentIds", multiple: true }),
      },
    ];
  }, []);
};
