import MessageComposer from "@meetora/react/ui/MessageComposer";
import MessageList from "@meetora/react/ui/MessageList";
import PeerAvatar from "@meetora/react/ui/PeerAvatar";
import PeerTitle from "@meetora/react/ui/PeerTitle";
import PeerDetail from "@meetora/react/ui/PeerDetail";
import PeerStatus from "@meetora/react/ui/PeerStatus";
import { TPeer } from "@meetora/schema/Peer";
import { FC, useCallback, useEffect, useState } from "react";
import { useChatMessagesStyles } from "./ChatMessages.styles";
import { Box, Card, EmptyCard, IconButton, MuiArrowBackIcon, Toolbar, Typography } from "@toolkit/ui";

type ChatMessagesProps = {
  onClose: () => void;
  peer: TPeer | undefined;
};

export const ChatMessages: FC<ChatMessagesProps> = ({ peer, onClose }) => {
  const { classes } = useChatMessagesStyles();
  const [details, setDetails] = useState(false);
  const handleToggleDetails = useCallback(() => setDetails(v => !v), []);
  useEffect(() => {
    setDetails(false);
  }, [peer]);

  if (!peer) {
    return (
      <Card className={classes.root}>
        <Box height={"100%"} display='flex' alignItems='center'>
          <EmptyCard title={"No conversation selected"} message='Please select a conversation to start chatting' />
        </Box>
      </Card>
    );
  }

  return (
    <Card className={classes.root}>
      {peer && (
        <Toolbar className={classes.header}>
          <IconButton sx={{ mr: 2 }} size='small' onClick={onClose}>
            <MuiArrowBackIcon />
          </IconButton>
          <Box className={classes.headerText} onClick={handleToggleDetails}>
            <Box sx={{ mr: 2 }}>
              <PeerAvatar peer={peer} size={40} />
            </Box>
            <Box sx={{ flex: 1, minWidth: 0 }}>
              <Typography className={classes.title}>
                <PeerTitle peer={peer} />
              </Typography>
              <Typography className={classes.subtitle} variant='body2'>
                <PeerStatus peer={peer} />
              </Typography>
            </Box>
          </Box>
        </Toolbar>
      )}
      <MessageList peer={peer} />
      <MessageComposer peer={peer} />

      {details && (
        <Box className={classes.details}>
          <PeerDetail peer={peer} onClose={handleToggleDetails} />
        </Box>
      )}
    </Card>
  );
};
