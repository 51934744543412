import { getAutocompleteFilter, createAutocompleteOption } from "@toolkit/ui";
import { useDoctorsTeamLeadersAutocompleteQuery } from "./gql";
export const createDoctorTeamLeaderAutocompleteOption = (doctor) => {
    return createAutocompleteOption({ id: doctor === null || doctor === void 0 ? void 0 : doctor.id, fullName: doctor === null || doctor === void 0 ? void 0 : doctor.fullName }, "id", "fullName");
};
export const getDoctorsTeamLeadersAutocompleteFilter = ({ name, multiple }) => {
    return getAutocompleteFilter({
        name,
        multiple,
        accessor: "teamLeaderDoctors",
        query: useDoctorsTeamLeadersAutocompleteQuery,
        labelBy: item => { var _a; return (_a = item === null || item === void 0 ? void 0 : item.user) === null || _a === void 0 ? void 0 : _a.fullName; },
        backendAccessor: item => { var _a; return (_a = item === null || item === void 0 ? void 0 : item.user) === null || _a === void 0 ? void 0 : _a.id; },
    });
};
