import { Box, Button, CustomIcon, IconButton, InfiniteScroll, Menu, MenuItem, PlusIcon, Typography } from "@toolkit/ui";
import React, { FC, useState } from "react";
import { useTranslation } from "@toolkit/i18n";
import {
  GuidedCareProgramMemberAddModal,
  GuidedCareProgramMemberAddModalApi,
} from "../../../modals/GuidedCareProgramMemberAdd/GuidedCareProgramMemberAddModal";
import { usePatientGuidedCareProgramsStyle } from "./usePatientGuidedCareProgramsStyle";
import { IPatientGuidedCareProgram } from "../../../types/types";

type PatientGuidedCareProgramsProps = {
  programs: IPatientGuidedCareProgram[];
  hasMore: boolean;
  selected?: string;
  onFetchMore: () => void;
  onChange: (programId?: string) => void;
};

export const PatientGuidedCarePrograms: FC<PatientGuidedCareProgramsProps> = props => {
  const { hasMore, programs, selected, onFetchMore, onChange } = props;

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const { t } = useTranslation("provider");

  const { classes, theme } = usePatientGuidedCareProgramsStyle();

  const selectedItem = programs?.find(item => selected === item?.id);

  const isOpen = !!anchorEl;

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSelect = (value: IPatientGuidedCareProgram) => {
    return () => {
      setAnchorEl(null);
      onChange(value?.id);
    };
  };

  const onPlusIconClick = () => {
    GuidedCareProgramMemberAddModalApi.open();
  };

  return (
    <Box>
      <GuidedCareProgramMemberAddModal />

      <Box className={classes.titleWrapper}>
        {programs?.length ? (
          <>
            <Typography className={classes.title}>{t("Programs")}</Typography>
            <IconButton onClick={onPlusIconClick}>
              <PlusIcon fontSize={"small"} fill={theme.palette.primary.main} />
            </IconButton>
          </>
        ) : (
          <Button
            fullWidth
            onClick={onPlusIconClick}
            sx={{
              whiteSpace: "nowrap",
            }}
          >
            {t("Add to care journey")}
          </Button>
        )}
      </Box>
      {!!programs?.length && (
        <>
          <Button
            id={"patient-add-activity-menu-button"}
            aria-controls={isOpen ? "patient-add-activity-menu" : undefined}
            aria-haspopup={"true"}
            aria-expanded={isOpen ? "true" : undefined}
            variant={"text"}
            disableElevation
            onClick={handleClick}
            className={classes.button}
            endIcon={
              <Box>
                <CustomIcon icon={"expandArrow"} color={"inherit"} viewBox={"-5 -5 22 22"} />
              </Box>
            }
          >
            <Box display={"flex"} flexDirection={"column"} alignItems={"baseline"}>
              <Typography fontSize={theme.mixins.fonts.fontSize.sm} whiteSpace={"nowrap"}>
                {selectedItem?.name}
              </Typography>
            </Box>
          </Button>

          <Menu
            className={classes.menu}
            id={"patient-add-activity-menu"}
            anchorEl={anchorEl}
            open={!!anchorEl}
            onClose={handleClose}
            classes={{
              paper: classes.menuPaper,
            }}
          >
            <Box id={"scrollableBox"} sx={{ maxHeight: 200, overflow: "auto" }}>
              <InfiniteScroll
                scrollableTarget={"scrollableBox"}
                dataLength={programs?.length!}
                // eslint-disable-next-line react/jsx-handler-names
                next={onFetchMore}
                hasMore={hasMore}
                loader={<Typography className={classes.loadingText}>{t("Loading...")}</Typography>}
              >
                {programs?.map(item => (
                  <MenuItem key={item?.id} onClick={handleSelect(item)}>
                    <Typography fontSize={theme.mixins.fonts.fontSize.sm}>{item?.name}</Typography>
                  </MenuItem>
                ))}
              </InfiniteScroll>
            </Box>
          </Menu>
        </>
      )}
    </Box>
  );
};
