import { FC } from "react";
import ContentLoader from "react-content-loader";

export const ChatListItemSkeleton: FC = () => {
  return (
    <ContentLoader width='100%' viewBox='0 0 200 400'>
      <circle cx='24' cy='22' r='15' />
      <rect x='50' y='12' width='335' height='3' />
      <rect x='50' y='20' width='335' height='3' />
      <rect x='50' y='28' width='335' height='3' />
    </ContentLoader>
  );
};
