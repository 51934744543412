import { useTranslation } from "@toolkit/i18n";
import { FC, useMemo } from "react";
import { TPeer } from "@meetora/schema/Peer";
import { ChatNavItemId } from "./Chat.hooks";
import { Box, Button, CircularProgress, CustomInfiniteScroll, Typography } from "@toolkit/ui";
import { ChatListItem } from "./ChatListItem";
import { useConversationsQuery } from "@meetora/react/ui/ConversationList";
import {
  conversationsFilterAnonymousChats,
  conversationsFilterBots,
  conversationsFilterChannels,
  conversationsFilterDialogs,
  conversationsFilterRegularChats,
  TConversationsFilter,
} from "@meetora/schema/conversation/ConversationsFilter";
import { ChatListItemSkeleton } from "./ChatListItemSkeleton";
import { useChatListStyles } from "./ChatList.styles";

type ChatListProps = {
  filter?: ChatNavItemId;
  peer?: TPeer;
  onSelect: (peer: TPeer | undefined) => void;
};

export const ChatList: FC<ChatListProps> = ({ filter, peer, onSelect }) => {
  const { t } = useTranslation("provider");
  const { classes, theme } = useChatListStyles();
  const filters: TConversationsFilter[] | undefined = useMemo(() => {
    switch (filter) {
      case "chats":
        return [conversationsFilterRegularChats(), conversationsFilterDialogs(), conversationsFilterBots()];
      case "channels":
        return [conversationsFilterChannels()];
      case "visits":
        return [conversationsFilterAnonymousChats()];
    }
  }, [filter]);

  const { data, fetchMore, hasMore, itemKey, loading, error, refetch: handleRetry } = useConversationsQuery({ filters });
  const isEmpty = data.count === 0;

  const noResultMessage = useMemo(() => {
    switch (filter) {
      case "channels":
        return t("No channels found.");
      case "visits":
        return t("No visits found.");
      case "chats":
        return t("No conversations found.");
    }
  }, [filter, t]);

  if (isEmpty) {
    return (
      <Box className={classes.noResult}>
        {loading && <CircularProgress />}
        {!loading && (
          <>
            <Typography fontSize={theme.mixins.fonts.fontSize.md}>{noResultMessage}</Typography>
            {error && <Button onClick={handleRetry}>{t("Retry")}</Button>}
          </>
        )}
      </Box>
    );
  }

  return (
    <Box id='chat-list-component' className={classes.root}>
      <CustomInfiniteScroll
        dataLength={data.count || 0}
        onFetchMore={fetchMore}
        hasMore={hasMore}
        scrollableTarget='chat-list-component'
        loader={<ChatListItemSkeleton />}
      >
        {data.conversations?.map((item, i) => (
          <ChatListItem
            key={itemKey(item, i)}
            isSelected={!!peer && itemKey(item, i) === itemKey({ peer }, i)}
            item={item}
            onSelect={onSelect}
          />
        ))}
      </CustomInfiniteScroll>
    </Box>
  );
};
