import { makeStyles } from "@toolkit/ui";

export const useChatStyles = makeStyles<{ hasSelectedItem: boolean }>()((theme, { hasSelectedItem }) => ({
  root: {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    padding: 16,
    gap: 16,
  },

  sidebar: {
    minWidth: 320,
    width: "25%",
    borderRight: "1px solid #f0f0f0",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      display: hasSelectedItem ? "none" : "flex",
    },
  },
}));
