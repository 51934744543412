import { teamMemberPositionOptions } from "@health/enum-options";
import { TeamMemberPosition } from "@health/queries/types";
import { getApolloContextFormToken } from "shared/utils";
import { useProviderGuidedCareHealthProgramTeamsLazyQuery } from "../gql";

export const useCustomGuidedCareHealthProgramTeamsQuery = ({ patientId, token, selectedHealthProgram }) => {
  const [getProviderGuidedCareHealthProgramTeamsQuery, { data: healthProgramTeamsQueryResult, fetchMore }] =
    useProviderGuidedCareHealthProgramTeamsLazyQuery({
      context: getApolloContextFormToken(token!),
    });

  const hasGuidedCareHealthProgramTeam = Boolean(healthProgramTeamsQueryResult?.providerGuidedCareHealthProgramTeams?.edges?.length);

  const guidedCareHealthProgramTeamsNode = healthProgramTeamsQueryResult?.providerGuidedCareHealthProgramTeams?.edges?.map(
    item => item?.node
  );
  const pageInfo = healthProgramTeamsQueryResult?.providerGuidedCareHealthProgramTeams?.pageInfo!;

  const guidedCareHealthPrograms =
    guidedCareHealthProgramTeamsNode?.map(item => ({
      id: item?.guidedCareHealthProgram?.id!,
      name: item?.guidedCareHealthProgram?.name!,
    })) || [];

  const selectedCareTeamMembers = guidedCareHealthProgramTeamsNode?.find(
    item => item?.guidedCareHealthProgram?.id === selectedHealthProgram
  )?.teamMembers;

  const guidedCareProgramTemplateId = guidedCareHealthProgramTeamsNode?.find(
    item => item?.guidedCareHealthProgram?.id === selectedHealthProgram
  )?.guidedCareHealthProgram?.guidedCareHealthProgramTemplate?.id;

  const guidedCareProgramTeamMembersWithoutTeamLeader = selectedCareTeamMembers
    ?.filter(member => member?.guidedCareProgramTeamMember?.position !== TeamMemberPosition.TeamLeader && !member?.isBackup)
    ?.map(item => ({
      id: item?.id!,
      photo: item?.user?.photo!,
    }));

  const guidedCareProgramTeamLeader = selectedCareTeamMembers?.find(
    member => member?.guidedCareProgramTeamMember?.position === TeamMemberPosition.TeamLeader && !member?.isBackup
  );

  const guidedCareProgramTeamLeaderPhoto = guidedCareProgramTeamLeader?.user?.photo;

  const getPosition = (position: string) => {
    return teamMemberPositionOptions.find(item => item.value === position)?.label;
  };

  const guidedCareProgramTeamMembers = [
    {
      key: guidedCareProgramTeamLeader?.id!,
      label:
        guidedCareProgramTeamLeader?.user?.firstName! +
        " " +
        guidedCareProgramTeamLeader?.user?.lastName! +
        " - " +
        getPosition(TeamMemberPosition.TeamLeader),
      value: TeamMemberPosition.TeamLeader,
    },
    ...(selectedCareTeamMembers
      ?.filter(member => member?.guidedCareProgramTeamMember?.position !== TeamMemberPosition.TeamLeader && !member?.isBackup)
      ?.map(item => ({
        key: item?.id!,
        label: item?.user?.firstName! + " " + item?.user?.lastName! + " - " + getPosition(item?.guidedCareProgramTeamMember?.position!),
        value: item?.guidedCareProgramTeamMember?.position!,
      })) || []),
  ];

  const handleFetchMore = () => {
    fetchMore({
      variables: {
        first: 10,
        filter: {
          patients: [patientId!],
        },
        after: pageInfo?.endCursor,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        return Object.assign({}, prev, {
          ...fetchMoreResult,
          providerGuidedCareHealthProgramTeams: {
            ...fetchMoreResult.providerGuidedCareHealthProgramTeams,
            edges: [
              ...(prev?.providerGuidedCareHealthProgramTeams?.edges || []),
              ...(fetchMoreResult?.providerGuidedCareHealthProgramTeams?.edges || []),
            ],
          },
        });
      },
    });
  };

  return {
    hasGuidedCareHealthProgramTeam, // hasTeam
    guidedCareHealthPrograms,
    guidedCareHealthProgramTeamPageInfo: pageInfo,
    guidedCareProgramTeamLeaderPhoto,
    guidedCareProgramTeamMembersWithoutTeamLeader, // careTeams
    guidedCareProgramTeamMembers, // teamMembers
    guidedCareProgramTemplateId,
    guidedCareHealthProgramTeamFetchMore: handleFetchMore,
    getProviderGuidedCareHealthProgramTeamsQuery,
  };
};
