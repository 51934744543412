import { MarketplaceOrderStatus } from "@health/queries/types";
import { i18n } from "@toolkit/i18n";
import { HeaderDetailsTab } from "./types";

export const HeaderDetailsTabMap: Array<HeaderDetailsTab> = [
  {
    label: i18n.t("All", { ns: "provider" }),
    statusEnums: [],
  },
  {
    label: i18n.t("New", { ns: "provider" }),
    statusEnums: [MarketplaceOrderStatus.New],
  },
  {
    label: i18n.t("In Progress", { ns: "provider" }),
    statusEnums: [MarketplaceOrderStatus.Accepted],
  },
  {
    label: i18n.t("Rejected", { ns: "provider" }),
    statusEnums: [MarketplaceOrderStatus.Rejected],
  },
  {
    label: i18n.t("Consumed", { ns: "provider" }),
    statusEnums: [MarketplaceOrderStatus.Delivered, MarketplaceOrderStatus.Consumed],
  },
];
