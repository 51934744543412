import { z } from "zod";
import { i18n } from "@toolkit/i18n";

export const vitalSignUpdateFormSchema = z.object({
  items: z.array(
    z.object({
      value: z
        .union([
          z.number(),
          z.string().refine(value => !value || /^[1-9]\d{1,2}\/[1-9]\d{1,2}$/.test(value), {
            message: i18n.t("(e.g. 120/80)", { ns: "provider" }),
          }),
        ])
        .nullish(),
      code: z.string(),
      display: z.string(),
      unit: z.string().nullish(),
    })
  ),
});

export type IVitalSignsUpdateFormValues = z.infer<typeof vitalSignUpdateFormSchema>;

export const vitalSignsUpdateFormDefaultValues: IVitalSignsUpdateFormValues = {
  items: [],
};
