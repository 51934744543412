/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable max-lines */
import { getAutocompleteEnumFilter, marketplaceApprovalStatusOptionsMap } from "@health/enum-options";
import { MarketplaceHealthPackageSortingField, OrderDirection } from "@health/queries/types";
import { TFunction, i18n } from "@toolkit/i18n";
import { Box, CustomTableColumnProps, format, TruncateTypography, Typography, useTheme } from "@toolkit/ui";
import { CloneDialog } from "./CloneDialog";
import { HealthPackageImages } from "./HealthPackageImages";
import { HealthPackageRequestReviewButton } from "./HealthPackageRequestReviewButton";
import { ToggleHealthPackagePublishStatus } from "./PublishHealthPackage";
import { DATE_FORMAT_FOR_TABLE_LONG } from "@toolkit/core";
import { getMarketplaceHealthPackageCategoriesAutocompleteFilter } from "@health/autocompletes";
import { HealthPackagesQueryNode } from "pages/HealthPackages/types";
import { isNumber, toNumber } from "lodash";

export const getHealthPackagesColumns = (t: TFunction): CustomTableColumnProps<HealthPackagesQueryNode>[] => {
  const { mixins } = useTheme();
  return [
    {
      key: "images",
      header: t("Images", { ns: "provider" }),
      accessor: row => <HealthPackageImages row={row} />,
    },
    {
      key: "name",
      header: t("Name", { ns: "provider" }),
      isSortable: true,
      type: "string",
      accessor: ({ name }) => <TruncateTypography text={name!} />,
      sortDirection: OrderDirection.Desc,
      sortColumnEnum: MarketplaceHealthPackageSortingField.Name,
      filter: {
        type: "string",
        name: "nameContains",
      },
    },
    {
      key: "nameAr",
      header: t("Arabic Name", { ns: "provider" }),
      isSortable: true,
      sortDirection: OrderDirection.Desc,
      sortColumnEnum: MarketplaceHealthPackageSortingField.NameAr,
      type: "string",
      accessor: ({ nameAr }) => <TruncateTypography text={nameAr!} />,
    },
    {
      key: "shortDescription",
      header: t("Short Description"),
      accessor: ({ shortDescription }) => <TruncateTypography text={shortDescription || "-"} />,
    },
    {
      key: "shortDescriptionAr",
      header: t("Arabic Short Description"),
      accessor: ({ shortDescriptionAr }) => <TruncateTypography text={shortDescriptionAr || "-"} />,
    },
    {
      key: "price",
      header: t("Price", { ns: "provider" }),
      accessor: ({ price }) => {
        const isNum = isNumber(toNumber(price));
        return (
          <Typography
            sx={{
              width: "100px",
            }}
          >
            {isNum ? parseFloat(price).toFixed(2) : "-"}
          </Typography>
        );
      },
    },
    {
      key: "discount",
      header: t("Discount"),
      accessor: ({ activeDiscount }) => activeDiscount?.amount || "-",
    },
    {
      key: "discountExpiryDate",
      header: t("Discount Expiry Date"),
      accessor: ({ activeDiscount }) => (
        <Box sx={{ minWidth: "max-content" }}>{format(activeDiscount?.endDate, DATE_FORMAT_FOR_TABLE_LONG, i18n.language) || "-"}</Box>
      ),
    },
    {
      key: "published",
      header: t("Publish Status", { ns: "provider" }),
      filter: getAutocompleteEnumFilter("YesNo", "isPublished"),
      accessor: row => <ToggleHealthPackagePublishStatus row={row} />,
    },
    {
      key: "status",
      header: t("Approval Status"),
      accessor: row => (
        <Typography fontSize={mixins.fonts.fontSize.sm}>{marketplaceApprovalStatusOptionsMap[row?.approvalStatus!]?.label}</Typography>
      ),
      filter: getAutocompleteEnumFilter("MarketplaceApprovalStatus", "approvalStatuses", { multiple: true }),
    },
    {
      key: "actions",
      header: t("Actions", { ns: "provider" }),
      accessor: row => (
        <Box sx={{ width: "max-content" }}>
          <HealthPackageRequestReviewButton row={row} />
          <CloneDialog row={row} />
        </Box>
      ),
    },
    {
      key: "fromPrice",
      header: t("From Price", { ns: "provider" }),
      showOnlyForFilterField: true,
      filter: {
        type: "number",
        name: "priceRange.min",
      },
      isHidden: true,
      accessor: row => row.price?.amount,
    },
    {
      key: "toPrice",
      header: t("To Price", { ns: "provider" }),
      showOnlyForFilterField: true,
      filter: {
        type: "number",
        name: "priceRange.max",
      },
      isHidden: true,
      accessor: row => row.price?.amount,
    },
    {
      key: "targetAge",
      header: t("Target Age"),
      showOnlyForFilterField: true,
      filter: {
        type: "number",
        name: "targetAge",
      },
      isHidden: true,
      accessor: row => row.targetFromAge,
    },
    {
      key: "targetToAge",
      header: t("To Age", { ns: "provider" }),
      showOnlyForFilterField: true,
      isHidden: true,
      accessor: row => row.targetToAge,
    },
    {
      key: "targetGender",
      header: t("Gender", { ns: "provider" }),
      showOnlyForFilterField: true,
      filter: getAutocompleteEnumFilter("MarketplaceGender", "targetGender"),
    },
    {
      key: "categoryCode",
      header: t("Category", { ns: "provider" }),
      type: "string",
      showOnlyForFilterField: true,
      filter: getMarketplaceHealthPackageCategoriesAutocompleteFilter({ name: "categoryId" }),
    },
  ];
};
