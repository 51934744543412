import { FC, useCallback, useMemo } from "react";
import { Badge, format, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText, Typography, useTheme } from "@toolkit/ui";
import { Conversation, ConversationListItemDescription } from "@meetora/react/ui/ConversationList";
import { TPeer } from "@meetora/schema/Peer";
import PeerTitle from "@meetora/react/ui/PeerTitle";
import PeerAvatar from "@meetora/react/ui/PeerAvatar";
import { i18n } from "@toolkit/i18n";
import moment from "moment";

type ChatListItemProps = {
  item: Conversation;
  isSelected: boolean;
  onSelect: (peer: TPeer | undefined) => void;
};

export const ChatListItem: FC<ChatListItemProps> = ({ item, isSelected, onSelect }) => {
  const theme = useTheme();
  const handleOnClick = useCallback(() => {
    onSelect(item.peer);
  }, [item.peer, onSelect]);

  const time = useMemo(() => {
    if (!item.displayMessageDate) {
      return null;
    }

    const value = moment(item.displayMessageDate * 1000);
    return format(value.toISOString(), value.isSame(moment(), "day") ? "hh:mm A" : "DD/MM/YYYY", i18n.language);
  }, [item.displayMessageDate]);

  return (
    <ListItem sx={{ cursor: "pointer" }} onClick={handleOnClick} selected={isSelected}>
      <ListItemAvatar>
        <PeerAvatar peer={item.peer} size={40} />
      </ListItemAvatar>
      <ListItemText
        primary={<PeerTitle peer={item.peer} />}
        primaryTypographyProps={{
          sx: { whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden", fontWeight: theme.mixins.fonts.fontWeight.medium },
        }}
        secondary={
          <>
            <ConversationListItemDescription conversation={item} />
            <Typography sx={{ position: "absolute", right: 10, bottom: 2 }} color='primary' fontSize={theme.mixins.fonts.fontSize.xxs}>
              {time}
            </Typography>
          </>
        }
      ></ListItemText>
      {item.unreadCount > 0 && (
        <ListItemSecondaryAction>
          <Badge badgeContent={item.unreadCount} color='primary' />
        </ListItemSecondaryAction>
      )}
    </ListItem>
  );
};
