import { UsersAutocomplete } from "@health/autocompletes";
import { teamMemberPositionToVendorUserTypeMap } from "@health/enum-options";
import { UserStatus } from "@health/queries/types";
import { useTranslation } from "@toolkit/i18n";
import { CustomTableColumnProps } from "@toolkit/ui";
import { useMemo } from "react";
import { IGuidedCareTeamMembers } from "pages/HealthProviderManagement/GuidedCare/types";

type GuidedCareTeamUpsertTableColumns = {
  selectedBranchId: string;
};

export const useGuidedCareTeamUpsertTableColumns = (
  props: GuidedCareTeamUpsertTableColumns
): CustomTableColumnProps<IGuidedCareTeamMembers>[] => {
  const { selectedBranchId } = props;

  const { t } = useTranslation("provider");

  return useMemo(() => {
    return [
      {
        key: "position",
        header: t("Position"),
        accessor: ({ position }) => t(position?.label!),
      },
      {
        key: "specialization",
        header: t("Specialization"),
        accessor: ({ specialization }) => t(specialization?.label!) || "-",
      },
      {
        key: "isLicensedHealthProfessional",
        header: t("Health License"),
        accessor: ({ isLicencedHealthProfessional: isLicensedHealthProfessional }) => {
          return isLicensedHealthProfessional ? t("Required") : "-";
        },
      },
      {
        key: "assignee",
        header: t("Assignee"),
        accessor: ({ position }, index) => (
          <UsersAutocomplete
            name={`teamMembers.${index}.user`}
            filter={{
              vendorUserType: teamMemberPositionToVendorUserTypeMap[position.value],
              status: UserStatus.Active,
              branchId: selectedBranchId,
            }}
            disabled={!selectedBranchId}
          />
        ),
      },
    ];
  }, [selectedBranchId, t]);
};
