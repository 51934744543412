import { FC } from "react";
import {
  Badge,
  Tab,
  Tabs,
  MuiCampaignIcon as ChannelIcon,
  MuiChatBubbleIcon as ChatIcon,
  MuiMedicalServicesIcon as VisitsIcon,
} from "@toolkit/ui";
import { ChatNavItemId } from "./Chat.hooks";
import { useUnreadMessagesCount } from "@meetora/react/sehacity/Chat";
import { useTranslation } from "@toolkit/i18n";

type ChatNavProps = {
  handleNav: (value: ChatNavItemId | undefined) => void;
  nav: ChatNavItemId | undefined;
};

export const ChatNav: FC<ChatNavProps> = ({ nav, handleNav }) => {
  const unreadCount = useUnreadMessagesCount();
  const { t } = useTranslation("provider");
  const handleChange = (event: React.SyntheticEvent, newValue: ChatNavItemId) => {
    handleNav(newValue);
  };

  return (
    <Tabs
      value={nav}
      onChange={handleChange}
      variant='fullWidth'
      TabIndicatorProps={{
        style: { display: "block" },
      }}
    >
      <Tab
        value='chats'
        label={t("Chat")}
        icon={unreadCount.chats > 0 ? <Badge badgeContent={unreadCount.chats} color='primary' sx={{ right: 10 }} /> : <ChatIcon />}
        iconPosition='start'
      />
      <Tab
        value='visits'
        label={t("Visits")}
        icon={unreadCount.visits > 0 ? <Badge badgeContent={unreadCount.chats} color='primary' sx={{ right: 10 }} /> : <VisitsIcon />}
        iconPosition='start'
      />
      <Tab
        value='channels'
        label={t("Channels")}
        icon={unreadCount.channels > 0 ? <Badge badgeContent={unreadCount.chats} color='primary' sx={{ right: 10 }} /> : <ChannelIcon />}
        iconPosition='start'
      />
    </Tabs>
  );
};
