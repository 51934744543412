import { Box } from "@toolkit/ui";
import { FC } from "react";
import { useDashboardContext } from "pages/dashboard/context/UserDoctorDashboardContextProvider";
import { ChatList } from "@/pages/Chat/components";

export const CareTeamChats: FC = () => {
  const { handleSelectPeer } = useDashboardContext();

  return (
    <Box width={"100%"} height={`330px`} display='block'>
      <Box width={"calc(100% - 2)"} height={"100%"} marginX={2}>
        <ChatList filter='visits' onSelect={handleSelectPeer} />
      </Box>
    </Box>
  );
};
