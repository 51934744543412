import { Chat as MeetoraChat } from "./components";
import NotificationBar from "@meetora/react/ui/NotificationBar";
import { useTranslation } from "@toolkit/i18n";
import { Box, useBreadCrumbs } from "@toolkit/ui";
import React, { useEffect } from "react";

export default function Chat(): React.ReactElement<unknown> {
  const { setBreadCrumb } = useBreadCrumbs();
  const { t } = useTranslation("provider");

  useEffect(() => {
    setBreadCrumb({ title: t("Chat"), values: [{ name: t("Chat"), route: "chat", id: "chat" }] });
  }, [setBreadCrumb, t]);

  return (
    <Box
      sx={{
        height: `calc(100vh - ${80}px)`,
        border: "none",
      }}
    >
      <MeetoraChat />
      <NotificationBar />
    </Box>
  );
}
