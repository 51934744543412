import { zodSchema } from "@health/autocompletes";
import { zodEnumSchema } from "@health/enum-options";
import { z } from "zod";

export const GuidedCareTaskUpdateFormSchema = z.object({
  status: zodEnumSchema.taskDefinitionStatus,
  assignees: z.array(zodSchema.guidedCareTeamUser).min(1),
});

export type IGuidedCareTaskUpdateFormValues = z.infer<typeof GuidedCareTaskUpdateFormSchema>;

export const guidedCareTaskUpdateFormDefaultValues: Partial<IGuidedCareTaskUpdateFormValues> = {
  status: undefined,
  assignees: [],
};
