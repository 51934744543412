import { zodSchema } from "@health/autocompletes";
import { z } from "zod";

export const appointmentServiceDetailsUpsertFormSchema = z.object({
  items: z.array(
    z.object({
      id: z.string().nullish(),
      service: zodSchema.systemCode,
      standard: z.string().min(1).max(255),
    })
  ),
});

export type IAppointmentServiceDetailsUpsertFormValues = z.infer<typeof appointmentServiceDetailsUpsertFormSchema>;

export const appointmentServiceDetailsUpsertFormDefaultValues: Partial<IAppointmentServiceDetailsUpsertFormValues> = {
  items: [],
};
