import { z } from "zod";
import { zodSchema } from "@health/autocompletes";
import { zodEnumSchema } from "@health/enum-options";

export const GuidedCareTeamUpsertFormSchema = z.object({
  name: z.string().min(1).max(70),
  description: z.string().min(1).max(255),
  maxNumberOfPatients: z.coerce.number().min(1),
  program: zodSchema.guidedCareProgram,
  branch: zodSchema.branch,
  isActive: z.boolean(),
  teamMembers: z.array(
    z.object({
      id: z.string().optional(),
      programTeamMemberId: z.string(),
      position: zodEnumSchema.teamMemberPosition,
      specialization: zodSchema.systemCode.nullish(),
      isLicencedHealthProfessional: z.boolean(),
      isBackup: z.boolean(),
      isKeyTeamMember: z.boolean(),
      user: zodSchema.user,
    })
  ),
});

export type IGuidedCareTeamUpsertFormValues = z.infer<typeof GuidedCareTeamUpsertFormSchema>;

export const guidedCareTeamUpsertFormDefaultValues: Partial<IGuidedCareTeamUpsertFormValues> = {
  name: undefined,
  description: undefined,
  maxNumberOfPatients: undefined,
  program: undefined,
  branch: undefined,
  isActive: true,
  teamMembers: [],
};
