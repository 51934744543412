import { makeStyles } from "@toolkit/ui";

export const useChatMessagesStyles = makeStyles()(theme => ({
  root: {
    height: "100%",
    flex: 1,
    minWidth: 0,
    display: "flex",
    flexDirection: "column",
    position: "relative",
  },

  header: {
    height: "100%",
    flex: 1,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "8px 16px",
    minHeight: 56,
    boxSizing: "border-box",
    cursor: "default",
  },

  headerText: {
    flex: 1,
    minWidth: 0,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    cursor: "pointer",
  },

  title: {
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
  },

  subtitle: {
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
  },

  details: {
    position: "absolute",
    width: "100%",
    height: "100%",
    backgroundColor: theme.palette.common.white,

    display: "flex",
    flexDirection: "column",
  },
}));
