import { Box, Card, useMediaQuery } from "@toolkit/ui";
import { ChatMessages } from "./ChatMessages";
import { ChatNav } from "./ChatNav";
import { useChatStyles } from "./Chat.styles";
import { useChatHooks } from "./Chat.hooks";
import { ChatList } from "./ChatList";

export const Chat = () => {
  const { nav, peer, handleSelect, handleClear, handleNav } = useChatHooks();
  const { classes, theme } = useChatStyles({ hasSelectedItem: !!peer });
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box className={classes.root}>
      <Card className={classes.sidebar}>
        <ChatNav nav={nav} handleNav={handleNav} />
        <ChatList filter={nav} peer={peer} onSelect={handleSelect} />
      </Card>

      {(!isSmall || peer) && (
        <Card sx={{ flex: 1 }}>
          <ChatMessages peer={peer} onClose={handleClear} />
        </Card>
      )}
    </Box>
  );
};
