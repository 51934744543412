import { getAutocompleteFilter, createAutocompleteOption } from "@toolkit/ui";
import { pickLocalizedValue } from "@toolkit/i18n";
import { useMarketplaceHealthPackagesAutocompleteQuery } from "./gql";
export const createMarketplaceHealthPackageAutocompleteOption = (healthPackage) => {
    return createAutocompleteOption({
        id: healthPackage === null || healthPackage === void 0 ? void 0 : healthPackage.id,
        name: healthPackage === null || healthPackage === void 0 ? void 0 : healthPackage.name,
        nameAr: healthPackage === null || healthPackage === void 0 ? void 0 : healthPackage.nameAr,
    }, "id", item => pickLocalizedValue(item === null || item === void 0 ? void 0 : item.name, item === null || item === void 0 ? void 0 : item.nameAr));
};
export const getMarketplaceHealthPackagesAutocompleteFilter = ({ name, multiple }) => {
    return getAutocompleteFilter({
        name,
        multiple,
        accessor: "marketplaceHealthPackages",
        query: useMarketplaceHealthPackagesAutocompleteQuery,
        labelBy: item => pickLocalizedValue(item === null || item === void 0 ? void 0 : item.name, item === null || item === void 0 ? void 0 : item.nameAr),
        backendAccessor: "id",
        filterSearchKey: "search",
    });
};
