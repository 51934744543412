import { makeStyles } from "@toolkit/ui";

export const useChatListStyles = makeStyles()(() => ({
  root: {
    overflow: "auto",
    height: "100%",
    width: "100%",
  },
  noResult: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: 8,
  },
}));
